<template>
  <section class="search-result">
    <div class="page-header">
      <h3 class="page-title">
        Search Result
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Sample Pages</a></li>
          <li class="breadcrumb-item active" aria-current="page">Search Result</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form action="#">
                  <div class="form-group d-lg-flex">
                    <input type="text" class="form-control" placeholder="Search Here" value="bootstrapdash">
                    <button type="submit" class="btn btn-primary ml-0 ml-lg-3 mt-2 mt-lg-0">Search</button>
                  </div>
                </form>
              </div>
              <div class="col-12 mb-5">
                <h2>Search Result For
                  <u class="ml-2">"bootstrapdash"</u>
                </h2>
                <p class="text-muted">About 12,100 results (0.52 seconds)</p>
              </div>
              <div class="col-12 results">
                <div class="pt-4 border-bottom">
                  <a class="d-block h4 mb-0" href="javascript:void(0);">bootstrapdash</a>
                  <a class="page-url text-primary" href="javascript:void(0);">https://www.bootstrapdash.com/</a>
                  <p class="page-description mt-1 w-75 text-muted">bootstrapdash gives you the most beautiful, free and premium bootstrap admin dashboard templates and control panel themes based on Bootstrap 3 and 4.</p>
                </div>
              </div>
              <div class="col-12 results">
                <div class="pt-4 border-bottom">
                  <a class="d-block h4 mb-0" href="javascript:void(0);">bootstrapdash Team – Medium</a>
                  <a class="page-url text-primary" href="javascript:void(0);">https://medium.com/@bootstrapdash</a>
                  <p class="page-description mt-1 w-75 text-muted">Read writing from bootstrapdash Team on Medium. We design and develop awesome admin dashboard templates with Bootstrap, so you can kick-start and speed up your development. www.bootstrapdash.com.</p>
                </div>
              </div>
              <div class="col-12 results">
                <div class="pt-4 border-bottom">
                  <a class="d-block h4 mb-0" href="javascript:void(0);">bootstrapdash · GitHub</a>
                  <a class="page-url text-primary" href="javascript:void(0);">https://github.com/bootstrapdash</a>
                  <p class="page-description mt-1 w-75 text-muted">bootstrapdash has 1 repository available. Follow their code on GitHub.</p>
                </div>
              </div>
              <div class="col-12 results">
                <div class="pt-4 border-bottom">
                  <a class="d-block h4 mb-0" href="javascript:void(0);">bootstrapdash.com - Home | Facebook</a>
                  <a class="page-url text-primary" href="javascript:void(0);">https://www.facebook.com/bootstrapdash</a>
                  <p class="page-description mt-1 w-75 text-muted">bootstrapdash.com. 9 likes · 4 talking about this. Clean, customisable admin dashboard template themes / UI WebApp Kits with a huge collection of...</p>
                </div>
              </div>
              <div class="col-12 results">
                <div class="pt-4 border-bottom">
                  <a class="d-block h4 mb-0" href="javascript:void(0);">bootstrapdash Team – Hacker Noon</a>
                  <a class="page-url text-primary" href="javascript:void(0);">https://hackernoon.com/@bootstrapdash</a>
                  <p class="page-description mt-1 w-75 text-muted">Oct 9, 2017 - Read writing from bootstrapdash Team in Hacker Noon. We design and develop awesome admin dashboard templates with Bootstrap, so you can kick-start and speed up your development. www.bootstrapdash.com. </p>
                </div>
              </div>
              <div class="col-12 results">
                <div class="pt-4 border-bottom">
                  <a class="d-block h4 mb-0" href="javascript:void(0);">bootstrapdash | Crunchbase</a>
                  <a class="page-url text-primary" href="javascript:void(0);">https://www.crunchbase.com/organization/bootstrapdash</a>
                  <p class="page-description mt-1 w-75 text-muted">bootstrapdash creates quality admin templates that are based on Bootstrap, the most popular front end development framework.</p>
                </div>
              </div>
              <div class="col-12 results">
                <div class="pt-4 border-bottom">
                  <a class="d-block h4 mb-0" href="javascript:void(0);">bootstrapdash.com (@bootstrapdash) | Twitter</a>
                  <a class="page-url text-primary" href="javascript:void(0);">https://twitter.com/@bootstrapdash</a>
                  <p class="page-description mt-1 w-75 text-muted">The latest Tweets from bootstrapdash.com (@bootstrapdash). Clean, customisable admin dashboard template themes / UI WebApp Kits with a huge collection of pre-built widgets and plugins.</p>
                </div>
              </div>
              <div class="col-12 results">
                <div class="pt-4 border-bottom">
                  <a class="d-block h4 mb-0" href="javascript:void(0);">Bootstrap Dash on Behance</a>
                  <a class="page-url text-primary" href="javascript:void(0);">https://www.behance.net/bootstrapdash</a>
                  <p class="page-description mt-1 w-75 text-muted">Awesome Bootstrap Admin Dashboard Templates. For Faster, Easier Web Applications Development. Clean, customizable admin dashboard template theme / UI WebApp Kit with a huge collection of pre-built widgets and plugins. Design and build
                    modern, robust, user-friendly web applications real fast!</p>
                </div>
              </div>
              <nav class="col-12" aria-label="Page navigation">
                <ul class="pagination mt-5">
                  <li class="page-item">
                    <a class="page-link" href="javascript:void(0);">Previous</a>
                  </li>
                  <li class="page-item  active">
                    <a class="page-link" href="javascript:void(0);">1</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="javascript:void(0);">2</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="javascript:void(0);">3</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="javascript:void(0);">Next</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'searchResult'
}
</script>